/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {Fragment, useState, useEffect} from 'react';
import {getUA} from 'react-device-detect';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';
import InputMask from 'react-input-mask';
import {Dialog, Transition, Listbox} from '@headlessui/react';

import classNames from 'classnames';
import * as Sentry from '@sentry/gatsby';
import {usePageContext} from '../../../context/pageContext';
import './style.css';

import {genAnonId, getClientId} from '../../../utils/analyticsUtils';

import redirect from '../../../assets/images/layout/redirect.png';
import caminoFundationImg from '../../../assets/images/layout/camino-fundation.svg';
import closeBtnImg from '../../../assets/images/layout/close-btn.svg';

const phoneRegExp = /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.●]?([0-9]{4})$/;

const BasicForm = (props) => {
  // eslint-disable-next-line no-restricted-globals
  // eslint-disable-next-line react/prop-types
  const {
    queryParams,
    appendQueryString,
    isDark,
    hasTitle,
    pageId,
    navToNewApp,
    location,
    onlyBusinessType,
  } = props;
  const {t} = useTranslation();

  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [openFundationModal, setOpenFundationModal] = useState(false);

  const schema = yup.object().shape({
    firstName: yup.string().required(`${t('gml10.form.firstnameError')}`),
    lastName: yup.string().required(`${t('gml10.form.lastnameError')}`),
    phone: yup
      .string()
      .required(`${t('gml10.form.phoneError')}`)
      .matches(phoneRegExp, `${t('gml10.form.phoneFormatError')}`),
    email: yup
      .string()
      .required(`${t('gml10.form.emailError')}`)
      .email(`${t('gml10.form.emailFormatError')}`),
    loanType: yup.string().required(`${t('gml10.form.loantypeError')}`),
    timeInBiz: yup.string().required(`${t('gml10.form.timeInBizError')}`),
    consent: yup.bool().oneOf([true], `${t('gml10.form.consentError')}`),
  });

  const {lang} = usePageContext();
  const spanish = navToNewApp ? 'es' : 'es';
  const english = navToNewApp ? 'en' : 'en';
  const payloadLang = lang === 'es' ? spanish : english;
  const {NODE_ENV} = process.env;
  const loanTypes = [
    {name: t('gml10.form.business'), value: 'business'},
    {name: t('gml10.form.personal'), value: 'personal'},
  ];

  const timeInBizOps = [
    {name: t('gml10.form.timeOpt1'), value: 'Less than 9 Months'},
    {name: t('gml10.form.timeOpt2'), value: 'Between 1 and 2 years'},
  ];

  const [userDetails, setUserDetails] = useState();
  const [userAgent, setUserAgent] = useState();
  const [firstName, setFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState();
  const [lastNameError, setLastNameError] = useState('');
  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState('');
  const [loanType, setLoanType] = useState(
    onlyBusinessType ? 'business' : undefined,
  );
  const [selectedLoanType, setSelectedLoanType] = useState(
    onlyBusinessType ? true : undefined,
  );
  const [loanTypeError, setLoanTypeError] = useState('');
  const [consent, setConsent] = useState(true);
  const [consentError, setConsentError] = useState('');
  const [timeInBiz, setTimeInBiz] = useState(
    onlyBusinessType ? 'Between 9 and 12 months' : '',
  );
  const [timeInBizError, setTimeInBizError] = useState('');

  const [selectedTimeInBiz, setSelectedTimeInBiz] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const errors = {
    firstNameError: (value) => setFirstNameError(value),
    lastNameError: (value) => setLastNameError(value),
    phoneError: (value) => setPhoneError(value),
    emailError: (value) => setEmailError(value),
    loanTypeError: (value) => setLoanTypeError(value),
    timeInBizError: (value) => setTimeInBizError(value),
    consentError: (value) => setConsentError(value),
  };

  const changeLoanType = (event) => {
    setLoanTypeError('');
    setSelectedLoanType(event);
    setLoanType(event.value);
  };

  const changeTimeInBiz = (event) => {
    setTimeInBizError('');
    setSelectedTimeInBiz(event);
    setTimeInBiz(event.value);
  };

  const selectClass = (placeholder) =>
    classNames(
      'relative w-full py-2 pl-4 pr-10 text-left bg-white border rounded-lg  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-md',
      {
        'text-gray-900': placeholder,
        'text-gray-500': !placeholder,
      },
    );

  const getUserGeolocationDetails = () => {
    // eslint-disable-next-line no-undef
    console.log(queryParams.ip);
    if (queryParams.ip) {
      fetch(
        `https://api.ipstack.com/${queryParams.ip}?access_key=d1fe3e87ffb9a4ab39c63603ce8de172`,
        {
          method: 'GET',
        },
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setUserDetails(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    } else {
      fetch(`${process.env.GATSBY_FUNCTIONS_URL}/GetUserContext`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          setUserDetails(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  };

  useEffect(() => {
    if (NODE_ENV !== 'development') {
      setUserAgent(getUA);
      getUserGeolocationDetails();
    }
  }, []);

  const sendApplication = () => {
    setIsSubmiting(true);

    const finalTimeInBiz = loanType === 'personal' ? '_' : timeInBiz;
    const finalTimeInMS = loanType === 'personal' ? 'under-9-months' : 'over-9-months';

    const formLead = {
      firstName,
      lastName,
      phone,
      email,
      loanType,
      timeInBiz: finalTimeInBiz,
      consent,
    };

    const pageQueryString =
      typeof window !== 'undefined' && window.location.search;
    const pageUrlParams = new URLSearchParams(pageQueryString);

    schema
      .validate(formLead, {abortEarly: false, recursive: true})
      .then(() => {
        fetch(`${process.env.GATSBY_CAMINO_LEADS_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            email,
            years_in_business: finalTimeInBiz,
            leadform_id: 'JAM',
            lang: payloadLang,
            language: payloadLang,
            google_analytics_client_id: getClientId(),
            user_agent: userAgent,
            loan_type: loanType,
            lead_geo: userDetails?.region_code,
            lead_device: 'Desktop',
            anonymous_id: genAnonId(),
            context_page_url: location?.href,
            cur_ur: location?.href,
            ip_address: userDetails?.ip,
          }),
        })
          .then((res) => {
            const qstring = appendQueryString.replace('?', '&');
            const onlineAppUrl = `${
              lang === 'es'
                ? process.env.GATSBY_FORM_REDIRECT_BL_ES
                : process.env.GATSBY_FORM_REDIRECT_BL_EN
            }?lang=${payloadLang}&first_name=${firstName}&last_name=${lastName}&email=${email}&contact_number=${phone}&loan_type=${loanType}&year_in_business=${finalTimeInBiz}&leadform_id=${pageId}${qstring}`;

            const personalLoanRedirect = `${
              lang === 'es'
                ? process.env.GATSBY_FORM_REDIRECT_PL_ES
                : process.env.GATSBY_FORM_REDIRECT_PL_EN
            }?lang=${payloadLang}&first_name=${firstName}&last_name=${lastName}&email=${email}&contact_number=${phone}&loan_type=personal&year_in_business=${finalTimeInBiz}&leadform_id=${pageId}${qstring}`;

            const businessLoanRedirect = onlineAppUrl;

            window.dataLayer = window.dataLayer || [];

            const event = loanType === 'personal' ? 'pl_lead' : 'bl_lead';
            window.dataLayer.push({
              event,
              variant: pageId,
            });
            gtag('event', event);

            let finalUrl = businessLoanRedirect;
            if (loanType === 'personal') {
              finalUrl = personalLoanRedirect;
            } else {
              if (
                finalTimeInBiz.includes('Under') ||
                finalTimeInBiz.includes('Less')
              ) {
                finalUrl = personalLoanRedirect;
              } else if (lang !== 'es') {
                setOpenFundationModal(true);
                finalUrl = '';
              }

            }

            if (finalUrl) window.location.href = finalUrl;

            return res.json();
          })
          .catch((error) => {
            Sentry.captureException(error);
            setIsSubmiting(false);
          });
      })
      .catch((err) => {
        err.inner.forEach((e) => {
          errors[`${e?.path}Error`](e?.message);
        });
        setIsSubmiting(false);
      });
  };

  return (
    <>
      {hasTitle && (
        <div className="my-1 md:mt-4 md:mb-2">
          {loanType !== 'business' && <div className="h-1" />}
          <div className="text-2xl md:text-3xl  font-semibold text-center font-sans  text-orange py-3">
            <span className="block">{t('gml10.splitSection.title')}</span>
          </div>
          {loanType !== 'business' && <div className="h-1" />}
        </div>
      )}
      <div className="lg:mt-0 lg:col-span-4">
        <div className="sm:max-w-lg sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
          <div className="px-6 sm:px-12">
            <div className="mt-1">
              <form action="#" method="POST" className="space-y-4">
                <div>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    autoComplete="firstname"
                    placeholder={t('gml10.form.firstname')}
                    required
                    value={firstName}
                    onChange={(e) => {
                      setFirstNameError('');
                      setFirstName(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {firstNameError !== '' && (
                  <span
                    className="mb-2 text-sm text-red-300"
                    id="firstnameError">
                    {firstNameError}
                  </span>
                )}
                <div>
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    autoComplete="lastname"
                    placeholder={t('gml10.form.lastname')}
                    required
                    value={lastName}
                    onChange={(e) => {
                      setLastNameError('');
                      setLastName(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {lastNameError !== '' && (
                  <span
                    className="mb-2 text-sm text-red-300"
                    id="lastNameError">
                    {lastNameError}
                  </span>
                )}
                <div>
                  <InputMask
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="nocomplete"
                    mask="(999) 999-9999"
                    placeholder={t('gml10.form.phone')}
                    required
                    value={phone}
                    onChange={(e) => {
                      setPhoneError('');
                      setPhone(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {phoneError !== '' && (
                  <span className="mb-2 text-sm text-red-300" id="phoneError">
                    {phoneError}
                  </span>
                )}
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="nocomplete"
                    placeholder={t('gml10.form.email')}
                    required
                    value={email}
                    onChange={(e) => {
                      setEmailError('');
                      setEmail(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {emailError !== '' && (
                  <span className="mb-2 text-sm text-red-300" id="emailError">
                    {emailError}
                  </span>
                )}

                {!onlyBusinessType && (
                  <div>
                    <Listbox value={selectedLoanType} onChange={changeLoanType}>
                      <div className="relative mt-1">
                        <Listbox.Button
                          className={() => selectClass(!!selectedLoanType)}>
                          <span className="block truncate">
                            {selectedLoanType
                              ? selectedLoanType.name
                              : t('gml10.form.loantype')}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {loanTypes.map((item) => (
                            <Listbox.Option
                              key={item.value}
                              className={({active}) =>
                                `${
                                  active
                                    ? 'text-amber-900 bg-amber-100'
                                    : 'text-gray-900'
                                }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                              }
                              value={item}>
                              {({selected, active}) => (
                                <>
                                  <span
                                    className={`${
                                      selected ? 'font-medium' : 'font-normal'
                                    } block truncate`}>
                                    {item.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`${
                                        active
                                          ? 'text-amber-600'
                                          : 'text-amber-600'
                                      }
                                absolute inset-y-0 left-0 flex items-center pl-3`}>
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </Listbox>

                    {loanTypeError !== '' && (
                      <span
                        className="mb-2 text-sm text-red-300"
                        id="loanTypeError">
                        {loanTypeError}
                      </span>
                    )}
                    {loanType !== 'business' && <div className="h-1" />}
                  </div>
                )}

                {selectedLoanType && loanType !== 'personal' && (
                  <div>
                    <Listbox
                      value={selectedTimeInBiz}
                      onChange={changeTimeInBiz}>
                      <div className="relative mt-1">
                        <Listbox.Button
                          className={() => selectClass(!!selectedTimeInBiz)}>
                          <span className="block truncate">
                            {selectedTimeInBiz
                              ? selectedTimeInBiz.name
                              : t('gml10.form.time')}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Listbox.Options className="z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {timeInBizOps.map((item) => (
                            <Listbox.Option
                              key={item.value}
                              className={({active}) =>
                                `${
                                  active
                                    ? 'text-amber-900 bg-amber-100'
                                    : 'text-gray-900'
                                }
              cursor-default select-none relative py-2 pl-10 pr-4`
                              }
                              value={item}>
                              {({selected, active}) => (
                                <>
                                  <span
                                    className={`${
                                      selected ? 'font-medium' : 'font-normal'
                                    } block truncate`}>
                                    {item.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`${
                                        active
                                          ? 'text-amber-600'
                                          : 'text-amber-600'
                                      }
                absolute inset-y-0 left-0 flex items-center pl-3`}>
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </Listbox>

                    {timeInBizError !== '' && (
                      <span
                        className="mb-2 text-sm text-red-300"
                        id="timeInBizError">
                        {timeInBizError}
                      </span>
                    )}
                  </div>
                )}

                <div className="sm:px-2">
                  <span
                    className={classNames('text-xl leading-none ', {
                      'text-white': isDark,
                      'text-gray-500': !isDark,
                    })}
                    style={{fontSize: 10, lineHeight: 1.2}}>
                    <div
                      className="lead_form__checkbox"
                      onClick={() => {
                        setConsent((val) => !val);
                        setConsentError('');
                      }}>
                      <input
                        type="checkbox"
                        checked={consent}
                        name="checkbox"
                        id="checkbox"
                        readOnly
                      />
                      <label>
                        {t('gml10.form.consent1')}
                        {t('gml10.form.consent3')}
                        <a
                          className="font-semibold"
                          href={`${process.env.GATSBY_CAMINO_URL}${
                            lang === 'es' ? '/es' : ''
                          }/terms-of-use/`}>
                          {t('gml10.form.consent4')}
                        </a>
                        {t('gml10.form.consent2')}
                        <a
                          className="font-semibold"
                          href={`${process.env.GATSBY_CAMINO_URL}${
                            lang === 'es' ? '/es' : ''
                          }/privacy-policy/`}>
                          {t('gml10.form.consent5')}
                        </a>
                        {t('gml10.form.consent6')}
                        <a
                          className="font-semibold"
                          href={`${process.env.GATSBY_CAMINO_URL}/electronic-communication-agreements/`}>
                          {t('gml10.form.consent7')}
                        </a>
                        {t('gml10.form.consent8')}
                      </label>
                    </div>
                  </span>
                  {consentError !== '' && (
                    <span
                      className="mb-2 text-sm text-red-300"
                      id="consentError">
                      {consentError}
                    </span>
                  )}
                  {loanType !== 'business' && <div className="h-1" />}
                </div>
                <div className="pb-6">
                  <button
                    onClick={() => sendApplication()}
                    type="button"
                    disabled={isSubmiting}
                    className="w-full flex justify-center py-3 px-4  border-transparent rounded-md shadow-sm text-xl text-white bg-orange-600 hover:bg-orange-400 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-orange-500 tracking-widest">
                    {isSubmiting && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {t('gml10.form.start_app')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={openPartnerModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpenPartnerModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center ">
                      <img
                        src={redirect}
                        width="300"
                        height="100"
                        alt="Workflow"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900">
                        {t('gml10.partnerModal.title')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 mb-3">
                          {t('gml10.partnerModal.subtitle')}
                        </p>

                        <p className="text-sm text-gray-500 mb-3">
                          {t('gml10.partnerModal.description')}
                        </p>
                        {t('gml10.partnerModal.description2') !== '' && (
                          <p className="text-sm text-gray-500">
                            {t('gml10.partnerModal.description2')}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <a
                      href="https://www.onemainfinancial.com/affiliate/8c83344c?CSCALD=31422&TRKCD=xxxx&utm_source=cf&utm_medium=affiliate&utm_campaign=pl"
                      type="button"
                      className="text-center inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-300 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                      {t('gml10.partnerModal.cta1')}
                    </a>
                  </div>
                  <div className="mt-2 sm:mt-2">
                    <button
                      type="button"
                      className="text-center inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-indigo-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => setOpenPartnerModal(false)}>
                      {t('gml10.partnerModal.cta2')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openFundationModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-2002"
          onClose={() => setOpenFundationModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-2002 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative bg-white rounded-lg px-10 pt-10 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto mb-5 flex text-right justify-end">
                      <button
                        type="button"
                        className="outline-none cursor-pointer"
                        onClick={() => setOpenFundationModal(false)}
                      >
                        <img
                          src={closeBtnImg}
                          width="24"
                          height="24"
                          alt="Close"
                        />
                      </button>
                    </div>
                    <div className="mx-auto flex items-center justify-center">
                      <img
                        src={caminoFundationImg}
                        width="384"
                        height="165"
                        alt="Fundation"
                      />
                    </div>
                    <div className="mt-5 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-md leading-6 text-center font-bold text-gray-500">
                        A Better Funding Opportunity Awaits With Fundation!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 mb-3">
                          Great news! After reviewing your application, we noticed that you could get a bigger and better loan with our sister company, Fundation. Fundation's funding solutions are ideally suited for more established businesses such as yours. <strong>They offer business loans up to $250K and lines of credit up to $100K</strong>. This could be exactly what your business needs!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <a
                      href="https://secure.fundation.com/application/caminofinancial"
                      type="button"
                      className="text-center inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-orange-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500 md:text-base">
                      Continue to Fundation loan application
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BasicForm;