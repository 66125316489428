import React from 'react';
import logoSticky from '../../../assets/images/layout/BBB_icon.webp';
import './style.css';

export default function CtaBox() {
  return (
    <div className="bg-white mb-2">
      <div className="max-w-1xl mx-auto text-center  px-4  lg:px-4">
        <div className="max-w-xs  inline-flex items-center justify-center ">
          <img src={logoSticky} width="300" height="100" alt="Workflow" />
        </div>
      </div>
    </div>
  );
}
