import _Metatags from './common/metatags';
import _TopMenu from './common/topMenu';
import _HeroSplit from './common/heroSplit';
import _HeroSection from './common/heroSection';
import _StatsBox from './common/statsBox';
import _ImgStats from './common/imgStats';
import _StepsBox from './common/stepsBox';
import _ComparisonTable from './common/comparisonTable';
import _Faq from './common/faq';
import _Footer from './common/footer';
import _CtaBox from './common/ctaBox';
import _SplitWithImg from './common/splitWithImg';
import _SplitSection from './common/splitSection';
import _LogoSection from './common/logoSection';
import _MediaSection from './common/mediaSection';
import _CalculatorSection from './common/calculatorSection';

export const Metatags = _Metatags;
export const TopMenu = _TopMenu;
export const HeroSplit = _HeroSplit;
export const HeroSection = _HeroSection;
export const StatsBox = _StatsBox;
export const ImgStats = _ImgStats;
export const StepsBox = _StepsBox;
export const ComparisonTable = _ComparisonTable;
export const Faq = _Faq;
export const Footer = _Footer;
export const CtaBox = _CtaBox;
export const SplitWithImg = _SplitWithImg;
export const SplitSection = _SplitSection;
export const LogoSection = _LogoSection;
export const MediaSection = _MediaSection;
export const CalculatorSection = _CalculatorSection;
