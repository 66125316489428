import React from 'react';
import {useTranslation} from 'react-i18next';

import sideImg from '../../../assets/images/layout/salas_bros.webp';
import checkmark from '../../../assets/images/layout/checkbox-icon.webp';

const StepsBox = () => {
  const {t, i18n} = useTranslation();

  const feats = [
    {description: `${t('gml10.feats.feature1')} `},
    {description: `${t('gml10.feats.feature2')} `},
    {description: `${t('gml10.feats.feature3')} `},
    {description: `${t('gml10.feats.feature4')} `},
  ];
  return (
    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl py-6">
      <div className="relative">
        <h2 className=" text-center  text-3xl font-extrabold text-gray-500 sm:text-4xl">
          {i18n.language === 'es' && (
            <span>
              <span className="text-orange">{`${t(
                'gml10.feats.title1',
              )} `}</span>
              {`${t('gml10.feats.title2')} `}
            </span>
          )}
          {i18n.language === 'en' && (
            <span>
              {`${t('gml10.feats.title1')} `}
              <span className="text-orange">{`${t(
                'gml10.feats.title2',
              )} `}</span>
            </span>
          )}
        </h2>
      </div>

      <div className="relative mt-3 lg:mt-3 lg:grid lg:grid-cols-2 lg:gap-8 ">
        <div className="relative">
          <dl className="mt-12 pt-4 space-y-6">
            {feats.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`feat_1_${idx}`} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 ">
                    <img
                      className="relative mx-auto"
                      width="36px"
                      src={checkmark}
                      alt=""
                      style={{marginTop: -15}}
                    />
                  </div>
                  <p className="ml-12  text-xl font-semibold pt-1 font-medium text-gray-500">
                    {item.description}
                  </p>
                </dt>
              </div>
            ))}
          </dl>
        </div>

        <div className="mt-1 -mx-4 relative lg:mt-4" aria-hidden="true">
          <img className="relative mx-auto p-2" src={sideImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default StepsBox;
