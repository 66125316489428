export const IdentifyUser = () => {};

export const genAnonId = () => {
  let anonId = '';
  try {
    if (
      typeof window.analytics === 'object' &&
      window.analytics !== null &&
      typeof window.analytics.user === 'function'
    ) {
      anonId = window.analytics.user().anonymousId() || '';
    }
  } catch (error) {
    anonId = '';
  }

  return anonId;
};
export const getClientId = () => {
  let clientId = '';
  try {
    clientId = window.ga == null ? null : window.ga.getAll()[0].get('clientId');
  } catch (error) {
    clientId = '';
  }
  return clientId;
};
