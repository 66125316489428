/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import logoSticky from '../../../assets/images/layout/footer_logo.svg';
import {usePageContext} from '../../../context/pageContext';
import facebookIcon from '../../../assets/images/logos/facebook.svg';
import instagramIcon from '../../../assets/images/logos/instagram.svg';
import youtubeIcon from '../../../assets/images/logos/youtube.svg';
import whatsappIcon from '../../../assets/images/logos/whatsapp.svg';
import {getClientId} from '../../../utils/analyticsUtils';

import bbbLogo from '../../../assets/images/layout/BBB_footer.webp';
import cloudflareLogo from '../../../assets/images/layout/cloudflare.svg';

import './style.css';

const FooterBox = (props) => {
  // eslint-disable-next-line react/prop-types
  const {navToNewApp} = props;
  const {t} = useTranslation();
  const {lang, originalPath} = usePageContext();
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [isSubscribing, setIsSubscribing] = useState();
  const [subscribeServerMsg, setSubscribeServerMsg] = useState({
    message: '',
    success: true,
  });

  const spanish = navToNewApp ? 'es' : 'es';
  const english = navToNewApp ? 'en' : 'en';
  const payloadLang = lang === 'es' ? spanish : english;
  const cid = `&google_client_id=${getClientId()}`;

  const queryString = typeof window !== 'undefined' && window.location.search;
  const getQueryString = queryString.toString();
  const [appendQueryString, setAppendQueryString] = useState();

  useEffect(() => {
    setAppendQueryString(getQueryString);
  }, [getQueryString]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const subscribe = () => {
    setIsSubscribing(true);
    const listUrl =
      'https://caminofinancial.us8.list-manage.com/subscribe/post?u=f29120c103bdf17ffeff91401&id=a6f2333630';

    if (subscribeEmail !== '') {
      if (validateEmail(subscribeEmail)) {
        addToMailchimp(subscribeEmail, {SPANISH: lang === 'es'}, listUrl)
          .then((response) => {
            setIsSubscribing(false);
            setSubscribeEmail('');

            if (response.result === 'success') {
              setSubscribeServerMsg({
                message: 'You have successfully subscribed your email',
                success: true,
              });
            } else {
              setSubscribeServerMsg({
                message: `${subscribeEmail} is already subscribed`,
                success: false,
              });
            }
          })
          .catch(() => {
            setIsSubscribing(false);
            setSubscribeServerMsg({
              message: 'We were unable to subscribe your email',
              success: false,
            });
          });
      } else {
        setIsSubscribing(false);
        setSubscribeServerMsg({
          message: 'Please enter a valid email address',
          success: false,
        });
      }
    } else {
      setIsSubscribing(false);
      setSubscribeServerMsg({
        message: 'Your email address is required',
        success: false,
      });
    }
  };

  const navigation = {
    col1: [
      {
        name: t('gml10.footer.sitemap.link1.label'),
        href: t('gml10.footer.sitemap.link1.url'),
      },
      {
        name: t('gml10.footer.sitemap.link2.label'),
        href: `${
          navToNewApp
            ? process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
            : process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
        }${appendQueryString}${
          appendQueryString !== '' ? '&' : '?'
        }lang=${payloadLang}${cid}`,
      },
      {
        name: t('gml10.footer.sitemap.link3.label'),
        href: t('gml10.footer.sitemap.link3.url'),
      },
      {
        name: t('gml10.footer.sitemap.link4.label'),
        href: t('gml10.footer.sitemap.link4.url'),
      },
      {
        name: t('gml10.footer.sitemap.link5.label'),
        href: t('gml10.footer.sitemap.link5.url'),
      },
      {
        name: t('gml10.footer.sitemap.link6.label'),
        href: t('gml10.footer.sitemap.link6.url'),
      },
      // {
      //   name: t('gml10.footer.sitemap.link7.label'),
      //   href: t('gml10.footer.sitemap.link7.url'),
      // },
    ],
    col2: [
      {
        name: t('gml10.footer.sitemap.link8.label'),
        href: t('gml10.footer.sitemap.link8.url'),
      },
      {
        name: t('gml10.footer.sitemap.link9.label'),
        href: t('gml10.footer.sitemap.link9.url'),
      },
      {
        name: t('gml10.footer.sitemap.link10.label'),
        href: t('gml10.footer.sitemap.link10.url'),
      },
      {
        name: t('gml10.footer.sitemap.link11.label'),
        href: t('gml10.footer.sitemap.link11.url'),
      },
      {
        name: t('gml10.footer.sitemap.link12.label'),
        href: t('gml10.footer.sitemap.link12.url'),
      },
      {
        name: t('gml10.footer.sitemap.link13.label'),
        href: t('gml10.footer.sitemap.link13.url'),
      },
      {
        name: t('gml10.footer.sitemap.link14.label'),
        href: t('gml10.footer.sitemap.link14.url'),
      },
      {
        name: t('gml10.footer.sitemap.link15.label'),
        href: t('gml10.footer.sitemap.link15.url'),
      },
    ],
    bottom: [
      {
        name: t('gml10.topNav.link8.label'),
        href: `/${
          lang === 'es' ? 'en' : 'es'
        }${originalPath}${appendQueryString}`,
      },
    ],
  };
  return (
    <footer className="bg-indigo-800" aria-labelledby="footerHeading">
      <div className="w-full bg-white">
        <div className="max-w-7xl mx-auto pt-12 pb-12 px-4 sm:px-6 lg:pt-16 lg:px-8">
          <h6 className="text-md mb-5 font-light text-orange tracking-widest">
            {t('gml10.footer.disclosures.title')}
          </h6>
          <p className="text-sm">{t('gml10.footer.disclosures.description')}</p>
        </div>
      </div>
      <div className="w-full">
        <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:pt-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="my-8 xl:mt-0">
              <h3 className="text-md font-semibold text-white tracking-widest uppercase">
                {t('gml10.footer.title1')}
              </h3>
              <p className="mt-4  mb-4 text-base text-gray-300">
                {t('gml10.footer.description1')}
              </p>

              <div className="w-full mt-2 flex rounded-md shadow-sm pr-3">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="text"
                    name="subscribe"
                    id="subscribe"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md px-2 sm:text-sm border-gray-300"
                    placeholder={t('gml10.footer.signupPlaceholder')}
                    value={subscribeEmail}
                    onChange={(e) => {
                      setSubscribeServerMsg('');
                      setSubscribeEmail(e.target.value);
                    }}
                  />
                </div>

                <button
                  type="button"
                  onClick={() => subscribe()}
                  disabled={isSubscribing}
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium rounded-r-md text-white  hover:bg-indigo-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 btnAqua">
                  {isSubscribing && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  )}
                  <span> {t('gml10.footer.signup')}</span>
                </button>
              </div>
              {subscribeServerMsg.message !== '' && (
                <span
                  className={classNames('mb-2 text-sm ', {
                    'text-red-300': !subscribeServerMsg.success,
                    'text-green-300': subscribeServerMsg.success,
                  })}
                  id="email-error">
                  {subscribeServerMsg.message}
                </span>
              )}

              <div
                style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
                <a href="https://www.facebook.com/caminofinancial">
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#b9d9e4',
                      width: 45,
                      height: 45,
                      borderRadius: 45,
                      marginRight: 15,
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                    <img
                      src={facebookIcon}
                      width="28"
                      height="28"
                      alt="facebook"
                    />
                  </div>
                </a>

                <a href="https://www.youtube.com/caminofinancial">
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#b9d9e4',
                      width: 45,
                      height: 45,
                      borderRadius: 45,
                      marginRight: 15,
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                    <img
                      src={youtubeIcon}
                      width="28"
                      height="28"
                      alt="youtube"
                    />
                  </div>
                </a>

                <a href="https://www.instagram.com/caminofinancial">
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#b9d9e4',
                      width: 45,
                      height: 45,
                      borderRadius: 45,
                      marginRight: 15,
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                    <img
                      src={instagramIcon}
                      width="28"
                      height="28"
                      alt="instagram"
                    />
                  </div>
                </a>

                <a href="https://wa.me/13252084229">
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#b9d9e4',
                      width: 45,
                      height: 45,
                      borderRadius: 45,
                      marginRight: 15,
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}>
                    <img
                      src={whatsappIcon}
                      width="28"
                      height="28"
                      alt="whatsapp"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-1 md:gap-8">
                <div className="mt-12 md:mt-0">
                  <h3 className="text-md font-semibold text-white tracking-widest uppercase">
                    {t('gml10.footer.title2')}
                  </h3>
                  <div className="mt-4 space-y-4 text-base text-gray-300 ">
                    {t('gml10.footer.description2')}
                  </div>
                  <div className="mt-4 space-y-4 text-base text-gray-300 ">
                    <a
                      href={t('gml10.footer.link2.url')}
                      className="text-md text-indigo-400 hover:text-white">
                      {t('gml10.footer.link2.label')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-md font-semibold text-white tracking-widest uppercase">
                    {t('gml10.footer.title3')}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.col1.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-md text-indigo-400 hover:text-white footerLink">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <ul className="mt-9 space-y-4">
                    {navigation.col2.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-md text-indigo-400 hover:text-white footerLink">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="my-8 border-t border-b border-gray-600 py-8 md:flex md:items-center md:justify-between">
            <a
              href={`${process.env.GATSBY_CAMINO_URL}/${lang}/${appendQueryString}`}>
              <img
                className="block h-12 w-auto py-1 "
                src={logoSticky}
                alt="logo_camino"
              />
            </a>
            <p className="mt-8  text-md text-indigo-400 md:mt-0 md:order-1 mx-3">
              309 E 8th Street, Suite #601 Los Angeles, CA 90014 &nbsp;&nbsp; |
              &nbsp;&nbsp; info@caminofinancial.com &nbsp;&nbsp; | &nbsp;&nbsp;
              Tel (800) 852-0655 &nbsp;&nbsp; | &nbsp;&nbsp; Fax (213) 260-6222
              <br />
              NMLS License:Salas & Company LLC #2186459 &nbsp;&nbsp; |
              &nbsp;&nbsp; CFL License: Salas & Company LLC (DBA Camino
              Financial) #60DBO-43053
              <br />
              CDFI Certification Number: 181CE054231 &nbsp;&nbsp; | &nbsp;&nbsp;
              <a href={t('solopreneur.url')}>{t('solopreneur.url')}</a>
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-8 xl:col-span-2 pb-6">
          <div className="mt-8 md:mt-0">
            <p className="mt-8 text-sm text-indigo-400 md:mt-0 md:order-1 mx-3">
              <a className="font-semibold" href={navigation.bottom[0].href}>
                {navigation.bottom[0].name}
              </a>
              &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
              <a
                className="font-semibold"
                href={t('gml10.footer.bottomFotter.link1.url')}>
                {t('gml10.footer.bottomFotter.link1.label')}
              </a>
              &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
              <a
                className="font-semibold"
                href={t('gml10.footer.bottomFotter.link2.url')}>
                {t('gml10.footer.bottomFotter.link2.label')}
              </a>
              &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
              <a
                className="font-semibold"
                href={t('gml10.footer.bottomFotter.link3.url')}>
                {t('gml10.footer.bottomFotter.link3.label')}
              </a>
            </p>
          </div>

          <div className="flex justify-center space-x-2 ">
            <p
              className="mt-8 text-xs text-indigo-400 md:mt-0 md:order-1 mx-3"
              style={{maxWidth: 800}}>
              © {t('gml10.footer.rightsReserved')}
            </p>
          </div>

          <div className="flex -right space-x-6 justify-end">
            <a
              href="https://www.bbb.org/us/ca/los-angeles/profile/small-business-loans/camino-financial-1216-411329"
              className="text-gray-400 hover:text-gray-500">
              <img
                className="h-12 py-1"
                src={bbbLogo}
                style={{width: 100, height: 40, marginTop: 10}}
                alt="logo_camino"
              />
            </a>
            <img
              className="h-12 py-1 "
              src={cloudflareLogo}
              alt="logo_camino"
              style={{width: 80, height: 60}}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterBox;
