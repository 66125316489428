import React from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import classNames from 'classnames';

import './style.css';

const USD = (value) => currency(value, {precision: 0}).format();
const ValueRow = ({
  label,
  value,
  isCurrency,
  valueSufix,
  containerStyle,
  isDark,
}) => {
  const labelStyle = classNames('float-left text-lg font-medium', {
    'text-gray-500': !isDark,
    'text-white': isDark,
  });

  const valueStyle = classNames('float-right text-lg font-bold', {
    'text-orange': !isDark,
    'text-white': isDark,
  });

  return (
    <div className={`flex justify-between items-center ${containerStyle}`}>
      <p className={labelStyle}>{`${label} `}</p>
      <p className={valueStyle}>{`${isCurrency ? USD(value) : value} ${
        valueSufix || ''
      }`}</p>
    </div>
  );
};

ValueRow.propTypes = {
  containerStyle: PropTypes.string,
  label: PropTypes.string.isRequired,
  valueSufix: PropTypes.string,
  isCurrency: PropTypes.bool,
  isDark: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

ValueRow.defaultProps = {
  isCurrency: false,
  valueSufix: '',
  containerStyle: '',
  isDark: false,
};

export default ValueRow;
