/* eslint-disable no-undef */
import React from 'react';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {usePageContext} from '../../../context/pageContext';

// eslint-disable-next-line react/prop-types
const SEO = () => {
  const {t} = useTranslation();
  const {lang} = usePageContext();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={`${t('siteMetadata.title')}`}
        titleTemplate={`%s | ${t('siteMetadata.titleTemplate')}`}
      />

      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_CONTAINER}');`}
        </script>
      </Helmet>

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_CONTAINER}`}
          height="0"
          width="0"
          title="GTM"
          style={{display: 'none', visibility: 'hidden'}}
        />
      </noscript>

      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA4_ID}`}
        />
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', '${process.env.GATSBY_GA4_ID}', {
            'page_title': '${t('siteMetadata.title')}',
            'currency': 'USD'
          });
          gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}', {
            'page_title': '${t('siteMetadata.title')}'
          });
        `}</script>
      </Helmet>

      <Helmet>
        <script>
          {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2636276,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
    </>
  );
};

export default SEO;
