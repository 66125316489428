import React from 'react';
import {useTranslation} from 'react-i18next';
import logoSticky from '../../../assets/images/layout/reviews.svg';
import './style.css';

const ImgStats = () => {
  const {t} = useTranslation();

  return (
    <div className="bg-gray-100">
      <div className="max-w-1xl mx-auto text-center py-4 px-4 sm:py-6 sm:px-6 lg:px-4">
        <p className="mt-4 text-lg leading-6 text-gray-400">
          {t('gml10.reviews.title')}
        </p>
        <div className="mt-4 max-w-xs  inline-flex items-center justify-center ">
          <img className="reviewsImage" src={logoSticky} alt="Workflow" />
        </div>
      </div>
    </div>
  );
};

export default ImgStats;
