/* eslint-disable no-restricted-properties */
/* eslint-disable no-undef */
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid';
import currency from 'currency.js';
// import {AnchorLink} from 'gatsby-plugin-anchor-links';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ValueRow} from '../../../atoms/common';
import SliderWithLabels from '../../../atoms/common/sliderWithLabels';
import {usePageContext} from '../../../context/pageContext';
import {getClientId} from '../../../utils/analyticsUtils';

const USD = (value) => currency(value, {precision: 0}).format();

// eslint-disable-next-line react/prop-types
const PaymentsCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(15000);
  const [paymentTerm, setPaymentTerm] = useState(30);
  const monthlyInterest = 2.35;
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalCostOfLoan, setTotalCostOfLoan] = useState(0);
  const [fundingFee, setFundingFee] = useState(0);
  const [docFee, setDocFee] = useState(0);
  const [closingFee, setClosingFee] = useState(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState();
  const [seeMoreInfo, setSeeMoreInfo] = useState(false);
  const {lang} = usePageContext();

  const queryString = typeof window !== 'undefined' && window.location.search;
  const getQueryString = queryString.toString();
  const [appendQueryString, setAppendQueryString] = useState();
  const cid = `google_client_id=${getClientId()}`;

  useEffect(() => {
    setAppendQueryString(getQueryString);
  }, [getQueryString]);

  const {t} = useTranslation();

  const calculateLoan = () => {
    const interest = monthlyInterest / 100;
    const pmtamount =
      (interest * -loanAmount * Math.pow(1 + interest, paymentTerm)) /
      (1 - Math.pow(1 + interest, paymentTerm));

    let beginingamount = loanAmount;
    let endingbalance = beginingamount;
    let intrest = 0;
    let total = 0;
    let principal = 0;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < paymentTerm; index++) {
      beginingamount = endingbalance;
      intrest = beginingamount * interest;
      total += intrest;
      principal = pmtamount - intrest;
      endingbalance = beginingamount - principal;
    }

    const fundingfee = 0;
    const docfee = 0;
    let closingfee = 0;

    if (loanAmount <= 50000) {
      closingfee = (loanAmount * 6.99) / 100;
    } else {
      closingfee = (loanAmount * 5) / 100;
    }

    const loancost = total + closingfee;

    setMonthlyPayment(pmtamount);
    setTotalCostOfLoan(loancost);
    setFundingFee(fundingfee);
    setDocFee(docfee);
    setClosingFee(closingfee);
    setTotalInterestPaid(total);
  };

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, paymentTerm, monthlyInterest]);

  return (
    <div
      className="relative p-2 lg:pt-6 lg:grid lg:grid-cols-2 lg:gap-8 rounded "
      style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
      <div className="relative md:block">
        <dl className="space-y-2 ">
          <SliderWithLabels
            isDark
            label={t('gml10.calculator.loan_amount')}
            min={5000}
            max={75000}
            step={125}
            isCurrency
            initialValue={15000}
            onChangeSlider={(value) => {
              setLoanAmount(value);
            }}
          />
          <SliderWithLabels
            isDark
            label={t('gml10.calculator.payment_term')}
            min={24}
            max={36}
            step={1}
            valueSufix={t('gml10.calculator.months')}
            initialValue={30}
            onChangeSlider={(value) => {
              setPaymentTerm(value);
            }}
          />
          <div className="hidden md:block  mx-10 bg-gray-50 border-t  mt-4 mb-4" />

          <ValueRow
            isDark
            label={t('gml10.calculator.total_cost_of_loan')}
            value={totalCostOfLoan}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-8 pt-4"
          />

          <div
            role="presentation"
            className="cursor-pointer mt-3 pt-4 pb-4 text-xs font-medium text-center font-sans  text-gray-400"
            onClick={() => setSeeMoreInfo((val) => !val)}>
            <div className="inline-flex items-center block ">
              {seeMoreInfo ? (
                <>
                  <span>{t('gml10.calculator.see_less')} </span>
                  <ChevronUpIcon className={` w-5 h-5 text-white-500 mb-1`} />
                </>
              ) : (
                <>
                  <span>{t('gml10.calculator.see_more')}</span>
                  <ChevronDownIcon className={` w-5 h-5 text-white-500 mb-1`} />
                </>
              )}
            </div>
          </div>

          {seeMoreInfo && (
            <div>
              <ValueRow
                isDark
                label={t('gml10.calculator.funding_fee')}
                value={fundingFee}
                isCurrency
                containerStyle="px-4 sm:px-10 mt-4"
              />

              <ValueRow
                isDark
                label={t('gml10.calculator.documentation_fee')}
                value={docFee}
                isCurrency
                containerStyle="px-4 sm:px-10 mt-4"
              />

              <ValueRow
                isDark
                label={t('gml10.calculator.closing_fee')}
                value={closingFee}
                isCurrency
                containerStyle="px-4 sm:px-10 mt-4"
              />

              <ValueRow
                isDark
                label={t('gml10.calculator.total_interest_paid')}
                value={totalInterestPaid}
                isCurrency
                containerStyle="px-4 sm:px-10 mt-4"
              />
            </div>
          )}
        </dl>
      </div>
      <div className="relative pt-10 md:block">
        <dl className="space-y-2 ">
          <div className="mt-2">
            <div className="text-sm font-medium text-center font-sans  text-white">
              <span className="block">{t('gml10.calculator.monthly')}</span>
            </div>
            <div className="text-4xl font-extrabold text-center font-sans  text-white  pt-4">
              <span className="block">{USD(monthlyPayment)}</span>
            </div>
          </div>

          <div className="mt-4">
            <div className="text-2xl md:text-3xl  font-semibold text-center font-sans  text-white align-center py-4">
              <a
                href={`${
                  lang === 'es'
                    ? 'https://www.caminofinancial.com/es/sign-up/'
                    : 'https://www.caminofinancial.com/sign-up/'
                }}${appendQueryString}${appendQueryString ? '&' : '?'}${cid}`}>
                <div className=" justify-center py-3 px-4  border-transparent rounded-md shadow-sm text-xl text-white bg-orange-600 hover:bg-orange-400 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 tracking-widest">
                  {t('gml10.form.start_app')}
                </div>
              </a>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default PaymentsCalculator;
