import React from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {usePageContext} from '../../../context/pageContext';

import './style.css';

// eslint-disable-next-line react/prop-types
export default function HeroSplit({rightComponent}) {
  const {t} = useTranslation();
  const {lang} = usePageContext();

  return (
    <div>
      <div
        className={classNames(
          ' h-full w-full object-centered ',
          lang !== 'es' ? 'heroImageEnglish' : 'heroImage',
        )}
      />
      <div className="relative">
        <div className="relative sm:overflow-hidden">
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-24 lg:px-8">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl  lg:col-span-6 lg:text-left lg:flex lg:items-left">
                  <div className="relative xs:absolute  mt-5  xs:mt-48 md:mt-25 lg:mt-96">
                    <h1 className="text-3xl tracking-wide font-semibold text-white sm:mt-5 text-center  lg:text-left leading-none lg:mt-6 lg:text-4xl xl:text-5xl">
                      <div className="md:block hero-text-shadow">
                        {t('gml10.hero.title1')}
                      </div>
                      <div className="md:block hero-text-shadow">
                        {t('gml10.hero.title2')}
                      </div>
                    </h1>
                    <p className="mt-3  tracking-wide font-400 text-gray-100 sm:mt-5 sm:text-lg lg:text-lg xl:text-lg sm:mt-5 text-center  leading-tight lg:text-left hero-text-shadow">
                      {t('gml10.hero.subtitle')}
                    </p>
                  </div>
                </div>
                <div className="px-1 mt-5 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto md:mt-22 lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div className="styledCard">
                    <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto rounded-lg innerCard sm:overflow-hidden ">
                      {rightComponent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
