/* eslint-disable no-restricted-properties */
/* eslint-disable no-undef */
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/solid';
import currency from 'currency.js';
// import {AnchorLink} from 'gatsby-plugin-anchor-links';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ValueRow} from '../../../atoms/common';
import SliderWithLabels from '../../../atoms/common/sliderWithLabels';
// import {usePageContext} from '../../../context/pageContext';

const USD = (value) => currency(value, {precision: 0}).format();

// eslint-disable-next-line react/prop-types
const PaymentsCalculator = ({gotoForm}) => {
  const [loanAmount, setLoanAmount] = useState(15000);
  const [paymentTerm, setPaymentTerm] = useState(36);
  const monthlyInterest = 2.35;
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalCostOfLoan, setTotalCostOfLoan] = useState(0);
  const [fundingFee, setFundingFee] = useState(0);
  const [docFee, setDocFee] = useState(0);
  const [closingFee, setClosingFee] = useState(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState();
  const [seeMoreInfo, setSeeMoreInfo] = useState(false);
  // const {lang, originalPath} = usePageContext();

  const {t} = useTranslation();

  const calculateLoan = () => {
    const interest = monthlyInterest / 100;
    const pmtamount =
      (interest * -loanAmount * Math.pow(1 + interest, paymentTerm)) /
      (1 - Math.pow(1 + interest, paymentTerm));

    let beginingamount = loanAmount;
    let endingbalance = beginingamount;
    let intrest = 0;
    let total = 0;
    let principal = 0;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < paymentTerm; index++) {
      beginingamount = endingbalance;
      intrest = beginingamount * interest;
      total += intrest;
      principal = pmtamount - intrest;
      endingbalance = beginingamount - principal;
    }

    const fundingfee = 0;
    const docfee = 0;
    let closingfee = 0;

    if (loanAmount <= 50000) {
      closingfee = (loanAmount * 6.99) / 100;
    } else {
      closingfee = (loanAmount * 5) / 100;
    }

    const loancost = total + closingfee;

    setMonthlyPayment(pmtamount);
    setTotalCostOfLoan(loancost);
    setFundingFee(fundingfee);
    setDocFee(docfee);
    setClosingFee(closingfee);
    setTotalInterestPaid(total);
  };

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, paymentTerm, monthlyInterest]);

  return (
    <>
      <div className="mt-4">
        <div className="text-2xl md:text-3xl  font-semibold text-center font-sans  text-orange  py-4">
          <span className="block">{t('gml10.calculator.title')}</span>
        </div>
      </div>

      <SliderWithLabels
        label={t('gml10.calculator.loan_amount')}
        min={5000}
        max={400000}
        step={125}
        isCurrency
        initialValue={15000}
        onChangeSlider={(value) => {
          setLoanAmount(value);
        }}
      />
      <SliderWithLabels
        label={t('gml10.calculator.payment_term')}
        min={24}
        max={60}
        step={1}
        valueSufix={t('gml10.calculator.months')}
        initialValue={36}
        onChangeSlider={(value) => {
          setPaymentTerm(value);
        }}
      />
      <div className="hidden md:block  mx-10 bg-gray-50 border-t-2  mt-2" />

      <div className="mt-2">
        <div className="text-4xl font-extrabold text-center font-sans  text-orange  pt-4">
          <span className="block">{USD(monthlyPayment)}</span>
        </div>
        <div className="text-sm font-medium text-center font-sans  text-orange">
          <span className="block">{t('gml10.calculator.monthly')}</span>
        </div>
      </div>
      {seeMoreInfo && (
        <div>
          <ValueRow
            label={t('gml10.calculator.total_cost_of_loan')}
            value={totalCostOfLoan}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-4"
          />

          <ValueRow
            label={t('gml10.calculator.funding_fee')}
            value={fundingFee}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-4"
          />

          <ValueRow
            label={t('gml10.calculator.documentation_fee')}
            value={docFee}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-4"
          />

          <ValueRow
            label={t('gml10.calculator.closing_fee')}
            value={closingFee}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-4"
          />

          <ValueRow
            label={t('gml10.calculator.total_interest_paid')}
            value={totalInterestPaid}
            isCurrency
            containerStyle="px-4 sm:px-10 mt-4"
          />
        </div>
      )}
      <div
        role="presentation"
        className="cursor-pointer mt-3 text-xs font-medium text-center font-sans  text-gray-400"
        onClick={() => setSeeMoreInfo((val) => !val)}>
        <div className="inline-flex items-center block ">
          {seeMoreInfo ? (
            <>
              <span>{t('gml10.calculator.see_less')} </span>
              <ChevronUpIcon className={` w-5 h-5 text-orange-500 mb-1`} />
            </>
          ) : (
            <>
              <span>{t('gml10.calculator.see_more')}</span>
              <ChevronDownIcon className={` w-5 h-5 text-orange-500 mb-1`} />
            </>
          )}
        </div>
      </div>

      <div className="px-6 md:px-16 pt-4 pb-10">
        <button
          onClick={() => gotoForm()}
          type="button"
          className="w-full flex justify-center  text-center py-3 px-6 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-orange hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 tracking-widest">
          {t('gml10.calculator.check_if_you_qualify')}
        </button>
      </div>
    </>
  );
};

export default PaymentsCalculator;
