import currency from 'currency.js';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from 'classnames';
import React, {useState} from 'react';
import './style.css';

const USD = (value) => currency(value, {precision: 0}).format();
const {Handle} = Slider;

const handle = (props) => {
  const {value, dragging, index, ...restProps} = props;
  return <Handle value={value} {...restProps} />;
};

const SliderWithLabels = (props) => {
  const {
    isCurrency,
    step,
    min,
    max,
    label,
    valueSufix,
    initialValue,
    onChangeSlider,
    isDark,
  } = props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChangeSlider(newValue);
  };

  const labelStyle = classNames('float-left text-lg font-medium', {
    'text-gray-500': !isDark,
    'text-white': isDark,
  });

  const valueStyle = classNames('float-right text-lg font-bold', {
    'text-orange': !isDark,
    'text-white': isDark,
  });

  const subLabeleft = classNames('float-left text-sm  font-medium  ', {
    'text-gray-400': !isDark,
    'text-gray-300': isDark,
  });
  const subLabeRight = classNames('float-right text-sm font-medium ', {
    'text-gray-400': !isDark,
    'text-gray-300': isDark,
  });

  return (
    <div className="px-4 py-3 sm:px-10">
      <div>
        <div className="mb-1">
          <p className={labelStyle}>{label}</p>
          <p className={valueStyle}>{`${isCurrency ? USD(value) : value} ${
            valueSufix || ''
          }`}</p>
          <div style={{clear: 'both'}} />
        </div>
        <Slider
          min={min}
          max={max}
          step={step}
          defaultValue={initialValue}
          onChange={handleChange}
          onAfterChange={handleChange}
          handleStyle={{
            borderColor: '#ff7825',
            borderWidth: 3,
            width: 22,
            height: 22,
            marginTop: -10,
          }}
          trackStyle={{
            borderColor: '#ff7825',
            backgroundColor: '#ff7825',
          }}
          railStyle={{
            backgroundColor: isDark ? 'rgba(255,120,37, 0.5)' : '#dddddd',
          }}
          handle={handle}
        />
        <div className="mt-1">
          <p className={subLabeleft}>{`${isCurrency ? USD(min) : min || ''} ${
            valueSufix || ''
          }`}</p>
          <p className={subLabeRight}>{`${isCurrency ? USD(max) : max || ''} ${
            valueSufix || ''
          }`}</p>
          <div style={{clear: 'both'}} />
        </div>
      </div>
    </div>
  );
};

SliderWithLabels.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  initialValue: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isCurrency: PropTypes.bool,
  valueSufix: PropTypes.string,
  isDark: PropTypes.bool,
  onChangeSlider: PropTypes.func.isRequired,
};
SliderWithLabels.defaultProps = {
  isCurrency: false,
  valueSufix: '',
  isDark: false,
};

export default SliderWithLabels;
