import {Popover, Transition} from '@headlessui/react';
import React, {Fragment} from 'react';
import './style.css';

const MenuPopover = () => {
  return (
    <Popover className="relative">
      {({open}) => (
        <>
          <Popover.Button
            className={`
              ${open ? '' : 'text-opacity-90'}
              text-black group bg-orange-400 px-3 py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
            <span>Solutions</span>
          </Popover.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel
              static
              className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform  sm:px-0 lg:max-w-1xl">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4 bg-gray-50">
                  <a
                    href="##"
                    className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                    <span className="flex items-center">
                      <span className="text-sm font-medium text-gray-900">
                        title
                      </span>
                    </span>
                    <span className="block text-sm text-gray-500">Text</span>
                  </a>

                  <a
                    href="##"
                    className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                    <span className="flex items-center">
                      <span className="text-sm font-medium text-gray-900">
                        title
                      </span>
                    </span>
                    <span className="block text-sm text-gray-500">Text</span>
                  </a>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

MenuPopover.propTypes = {};

MenuPopover.defaultProps = {};

export default MenuPopover;
