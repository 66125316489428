import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {usePageContext} from '../../../context/pageContext';
import {getClientId} from '../../../utils/analyticsUtils';
import './style.css';

// eslint-disable-next-line react/prop-types
export default function CtaBox() {
  // eslint-disable-next-line react/prop-types
  const {lang} = usePageContext();
  const {t} = useTranslation();
  const queryString = typeof window !== 'undefined' && window.location.search;
  const getQueryString = queryString.toString();
  const [appendQueryString, setAppendQueryString] = useState();
  const cid = `google_client_id=${getClientId()}`;

  useEffect(() => {
    setAppendQueryString(getQueryString);
  }, [getQueryString]);

  return (
    <div className="ctaBox">
      <div className="max-w-4xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl pb-4">
          <span className="block">{t('gml10.ctaBox.title1')}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-white">
          {t('gml10.ctaBox.description1')}
        </p>

        <a
          href={`${
            lang === 'es'
              ? 'https://www.caminofinancial.com/es/sign-up/'
              : 'https://www.caminofinancial.com/sign-up/'
          }}${appendQueryString}${appendQueryString ? '&' : '?'}${cid}`}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3  border-transparent text-xl font-medium rounded-md text-white bg-orange hover:bg-orange-400 sm:w-auto tracking-widest">
          {t('gml10.ctaBox.btnText')}
        </a>
      </div>
    </div>
  );
}
