import React from 'react';
import './style.css';

// eslint-disable-next-line react/prop-types
const StyledCard = ({children, ...props}) => (
  <div className="styledCard" {...props}>
    {children}
  </div>
);

StyledCard.propTypes = {};
StyledCard.defaultProps = {};

export default StyledCard;
