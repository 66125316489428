export const BuildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date)) {
    Object.keys(data).forEach((key) => {
      this.buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export const paramsToObject = (entries) => {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
