import {Disclosure} from '@headlessui/react';
import PropTypes from 'prop-types';
import React from 'react';
import './style.css';
import {MinusSmIcon, PlusSmIcon} from '@heroicons/react/solid';

// eslint-disable-next-line react/prop-types
const DisclosureRow = ({title, description, defaultVal}) => {
  return (
    <Disclosure className="my-4 py-4" defaultOpen={defaultVal}>
      {(innerProps) => {
        const {open} = innerProps;
        return (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-grey-900 bg-grey-100 rounded-lg hover:bg-grey-200 focus:outline-none focus-visible:ring focus-visible:ring-grey-500 focus-visible:ring-opacity-75">
              <span className="w-9/12">{title}</span>
              {open ? (
                <MinusSmIcon className={` 	w-8 h-8 text-orange`} />
              ) : (
                <PlusSmIcon className={` w-8 h-8 text-orange`} />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-1 pb-4 text-sm text-gray-500">
              {description}
            </Disclosure.Panel>
            <div className="w-full border-t border-gray-300" />
          </>
        );
      }}
    </Disclosure>
  );
};

DisclosureRow.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
};

DisclosureRow.defaultProps = {};

export default DisclosureRow;
