import React from 'react';
import {useTranslation} from 'react-i18next';
import logo5 from '../../../assets/images/logos/bostinno.webp';
import logo1 from '../../../assets/images/logos/cnn.webp';
import logo4 from '../../../assets/images/logos/hbs.webp';
import logo6 from '../../../assets/images/logos/latinpost.webp';
import logo2 from '../../../assets/images/logos/nbc.webp';
import logo3 from '../../../assets/images/logos/univision.webp';

export default function Faq() {
  const {t} = useTranslation();

  return (
    <div>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto pt-8 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-lg font-semibold text-gray-400 tracking-wide">
            {t('gml10.hero.media_presence')}
          </p>
          <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 ">
              <img className="logoImage" src={logo1} alt="CNN" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="logoImage" src={logo2} alt="NBC" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="logoImage" src={logo3} alt="Univision" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2  lg:col-span-1">
              <img
                className="logoImage"
                src={logo4}
                alt="Harvard Business School"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2  lg:col-span-1">
              <img className="logoImage" src={logo5} alt="Bostino" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2  lg:col-span-1">
              <img className="logoImage" src={logo6} alt="Latin Post" />
            </div>
          </div>

          <div className="w-full mt-6 border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
}
