/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Disclosure} from '../../../atoms/common';
import {usePageContext} from '../../../context/pageContext';

export default function Faq() {
  const {t} = useTranslation();
  const {lang} = usePageContext();
  const faqArray =
    lang === 'en'
      ? [
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500">
                1. What are the requirements?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <ol>
                  <li className="mb-2 list-disc">
                    Own a business operating for at least 9 months
                  </li>
                  <li className="mb-2 list-disc">
                    Minimum monthly income of $2,500
                  </li>
                  <li className="mb-2 list-disc">An ITIN or SSN</li>
                  <li className="mb-2 list-disc">
                    No bankruptcies in the last 2 years
                  </li>
                  <li className="mb-2 list-disc">
                    Current on outstanding credit obligations
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                2. How can I get a business loan?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p className="mb-2 ">Follow these 4 steps:</p>

                <ol>
                  <li className="mb-2 list-decimal">
                    Apply online (100% paperless)
                  </li>
                  <li className="mb-2 list-decimal">
                    Verify your bank account with Plaid
                  </li>
                  <li className="mb-2 list-decimal">
                    Share some basic documents with us
                  </li>
                  <li className="mb-2 list-decimal">
                    Sign your contract online
                  </li>
                </ol>
                <p className="mt-4">
                  You can receive your money in just 2 days!
                </p>
              </div>
            ),
          },
          {
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                3. What type of business loan does Camino Financial offer?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  We offer flexible business loans with minimal requirements.
                  You can use them to buy equipment or as working capital.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                4. Can I apply if I don't have an SSN?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Yes! You can apply with your ITIN, just like more than half of
                  our clients that don’t have an SSN.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                5. Can I apply if I don’t have a credit history?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Yes, we trust you!</p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                6. Do Camino Financial loans require collateral?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>No, we don’t require any type of collateral.</p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                7. Is the lending process safe?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Yes. 4.7 stars and more than 700 reviews prove it. We use the
                  same technology that banks use to protect your private
                  information.
                </p>
              </div>
            ),
          },
        ]
      : [
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500">
                1. ¿Cuáles son los requisitos?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <ol>
                  <li className="mb-2 list-disc">
                    Tener un negocio al menos 9 meses operando
                  </li>
                  <li className="mb-2 list-disc">
                    Ingresos mínimos de $2,500 al mes
                  </li>
                  <li className="mb-2 list-disc">Tener un ITIN o SSN</li>
                  <li className="mb-2 list-disc">
                    No haber declarado bancarrota en 2 años
                  </li>
                  <li className="mb-2 list-disc">
                    Estar al corriente con deudas existentes
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                2. ¿Cómo puedo obtener un préstamo para negocios?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p className="mb-2 ">Sigue estos 4 pasos:</p>
                <ol>
                  <li className="mb-2 list-decimal">
                    Aplica en línea (Sin papeleo)
                  </li>
                  <li className="mb-2 list-decimal">
                    Verifica tu cuenta bancaria con Plaid
                  </li>
                  <li className="mb-2 list-decimal">
                    Envía documentación básica
                  </li>
                  <li className="mb-2 list-decimal">
                    Firma tu contrato en línea
                  </li>
                </ol>
                <p className="mt-4">¡Recibe tu dinero en 2 días!</p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                3. ¿Qué tipo de préstamo para negocio ofrece Camino Financial?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Ofrecemos un préstamo de negocio flexible y con requisitos
                  mínimos. Puedes usarlo para comprar equipo o como capital de
                  trabajo.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                4. ¿Califico si no tengo SSN?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Sí, aplica usando tu Número de Identificación Personal del
                  Contribuyente (ITIN). Más de la mitad de nuestros clientes
                  aplicaron sin Número de Seguridad Social (SSN).
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                5. ¿Califico si no tengo historial crediticio?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>¡Sí, nosotros confiamos en ti!</p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                6. ¿Necesito dejar una garantía para solicitar un préstamo de
                Camino Financial?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>No, no pedimos colateral o garantía.</p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                7. ¿Es seguro el proceso de financiamiento?
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Sí, nuestros más de 700 reviews con 4.7 estrellas lo
                  comprueban. Utilizamos la misma tecnología que utilizan los
                  bancos para proteger tu información.
                </p>
              </div>
            ),
          },
        ];

  const [faqs] = useState([...faqArray]);

  return (
    <>
      <div className="transition duration-500 ease-in bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full px-4 pt-16 pb-6">
          <div className="relative">
            <h2 className="text-left text-4xl font-extrabold text-gray-500 sm:text-4xl">
              {t('gml10.faq.title')}
            </h2>
          </div>
          <div className="w-full mt-8">
            {faqs.map((item, idx) => (
              <Disclosure
                // eslint-disable-next-line react/no-array-index-key
                key={`question_${idx}`}
                title={item.question}
                description={item.answer}
                defaultVal={item.isOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
