/* eslint-disable react/prop-types */
import React from 'react';
import {useTranslation} from 'react-i18next';
import sideImg from '../../../assets/images/layout/prequalify.webp';
import {BasicForm} from '../../../molecules';

import './style.css';

const StepsBox = (props) => {
  const {t} = useTranslation();
  // eslint-disable-next-line react/prop-types
  const {
    queryParams,
    appendQueryString,
    pageId,
    justBiz,
    navToNewApp,
    location,
  } = props;
  return (
    <div id="applynow" className="splitBox">
      <div className="py-8 overflow-hidden lg:py-10">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-4xl font-extrabold text-white sm:text-4xl">
              {t('gml10.splitSection.title')}
            </h2>
          </div>
          <div className="relative mt-1 lg:mt-1 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative hidden md:block">
              <dl className="space-y-2 ">
                <img className="relative mx-auto mt-12" src={sideImg} alt="" />
              </dl>
            </div>

            <div
              className="mt-0 -mx-4 relative mt-1 lg:mt-2"
              aria-hidden="true">
              <BasicForm
                queryParams={queryParams}
                appendQueryString={appendQueryString}
                isDark
                justBiz={justBiz}
                pageId={pageId}
                navToNewApp={navToNewApp}
                location={location}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsBox;
