/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Disclosure} from '@headlessui/react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import React, {Fragment, useEffect, useState} from 'react';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

import logoSticky from '../../../assets/images/layout/logo-spn-color.svg';
import logoStickyWhite from '../../../assets/images/layout/logo-spn-white.svg';
import {usePageContext} from '../../../context/pageContext';
import './style.css';

export default function TopMenu() {
  // eslint-disable-next-line react/prop-types
  const {t} = useTranslation();
  // eslint-disable-next-line react/prop-types

  const queryString = typeof window !== 'undefined' && window.location.search;
  const getQueryString = queryString.toString();
  const [appendQueryString, setAppendQueryString] = useState();

  const {lang, originalPath} = usePageContext();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const header = document.getElementById('CaminoHeader');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      // eslint-disable-next-line no-restricted-globals
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        setIsSticky(true);
      } else {
        header.classList.remove('sticky');
        setIsSticky(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  useEffect(() => {
    setAppendQueryString(getQueryString);
  }, [getQueryString]);

  const linkClass2 = classNames(
    'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const langToggleClass = classNames(
    'ml-2 inline-flex items-center px-1 pt-1 text-xs font-medium',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const navigation = [
    {
      name: t('gml10.topNav.link1.label'),
      href: `${t('gml10.topNav.link1.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link2.label'),
      href: `${t('gml10.topNav.link3nav.link2.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3.label'),
      href: `${t('gml10.topNav.link3nav.link3.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link4.label'),
      href: `${t('gml10.topNav.link3nav.link4.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link5.label'),
      href: `${t('gml10.topNav.link3nav.link5.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link6.label'),
      href: `${t('gml10.topNav.link3nav.link6.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link8.label'),
      href: `${t('gml10.topNav.link3nav.link7.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link8.label'),
      href: `/${
        lang === 'es' ? 'en' : 'es'
      }${originalPath}${appendQueryString}`,
    },
  ];

  return (
    <Disclosure id="CaminoHeader" as="nav" className="sticky_header">
      {() => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center lg:mr-12">
                  <a
                    href={`${process.env.GATSBY_CAMINO_URL}/${lang}/${appendQueryString}`}>
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={isSticky ? logoSticky : logoStickyWhite}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={
                        (isSticky && logoSticky) || lang === 'es'
                          ? logoSticky
                          : logoStickyWhite
                      }
                      alt="Workflow"
                    />
                  </a>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <a href={navigation[7].href} className={langToggleClass}>
                  {navigation[7].name}
                </a>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <a href={navigation[7].href} className={langToggleClass}>
                  {navigation[7].name}
                </a>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xs:hidden bg-white">
            <div className="pt-2 pb-3 space-y-1">
              <a href={navigation[7].href} className={linkClass2}>
                {navigation[7].name}
              </a>

              <div className="pt-4 pb-3 border-t border-gray-200" />

              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/covid-19/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link1.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/small-business-loans/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link2.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/blog/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link3.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/success-stories/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link4.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/about-us/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link5.label')}
              </a>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="mt-3 space-y-1 px-3">
                <AnchorLink
                  to={`/${lang}${originalPath}#applynow`}
                  type="button"
                  className="w-full flex justify-center py-2 px-4  border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-orange-600 hover:bg-orange-400">
                  {t('gml10.topNav.link7.label')}
                </AnchorLink>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
